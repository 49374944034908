body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Open Sans', 'Noto Sans', 'Montserrat', 'Antic Slab', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, .ant-input {
  font-family: 'Poppins';
}

.ant-table table { 
  font-family: 'Poppins'; 
  font-size: 13px;
}

.ant-input {
  padding: 7px 11px;
}

.ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  background: #00A0E9;
  padding: 8px 0;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.color-black {
  color: #000000 !important;
  /* font-weight: 600; */
  opacity: 0.8;
}

.sales-list {
  .ant-table-thead .ant-table-cell {
    background-color: #F5EEE6;
    /* color: #FFFFFF; */
    /* font-weight: 500; */
  }
}

.ant-collapse-header-text {
  font-weight: 700;
}

.ant-input-number-input {
  font-family: 'poppins' !important;
}

.input-number-with-prefix {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  color: #000000 !important;
}

.payment-mode-h-row {
  font-family: 'poppins';
  font-weight: bold;
}

.payment-mode-row {
  font-family: 'poppins';  
}

.flex-je-ac {
  display: flex;
  justify-content: end;
  align-items: center;
}

.border-left {
  border-left: 1px solid #000000 !important;
}

.border-top {
  border-top: 1px solid #000000 !important;
}

.pl-10px {
  padding-left: 10px;
}

.pr-10px {
  padding-right: 10px;
}

.pt-10px {
  padding-left: 10px;
}

.pt-5px {
  padding-top: 5px;
}

.pb-5px {
  padding-bottom: 5px;
}
