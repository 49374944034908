.detailsContainer {
  box-sizing: border-box;
  height: 100vh;
  display: flex;
}

.detailsContainer {
  box-sizing: border-box;
  flex: 1;
  height: calc(100vh);
  background-color: #f3f5f9;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
}
.completePage {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.content-area {
  flex: 1 1;
  box-sizing: border-box;
  background-color: #f3f3f3;
  margin-top: 0.5rem;
  padding-top: 1.5rem;
  overflow: auto;
}

/* .pageTitle {
  box-sizing: border-box;
  background-color: white;
  padding: 5px 5px;
  padding-left: 20px;
  border-bottom: 1px solid #f2e0f7;
  top: 0;
  display: flex;
  height: fit-content;
  align-items: center;
  position: sticky;
  top: 0px;
}

.pageTitleText {
  vertical-align: top;
  padding-left: 10px;
  line-height: 1.5rem;
  font-size: 12px;
  color: #1f43e5;
  font-weight: 500;
  text-transform: uppercase;
  flex: 1 1;
}

.pageTitleImage {
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
} */

.formAreaStyle {
  width: 1100px;
  margin: 0px auto 4rem;
  padding: 3rem;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: white;
}

.topBannerDiv {
  box-sizing: border-box;
  height: 2rem;
  background-color: #fff;
  display: flex;
  border-bottom: 1px solid #f2e0f7;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.formElement {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.overlay-loader {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

#circularG{
	position: relative;
	width: 68px;
	height: 68px;
	margin-top: 17%;
  margin-left: 50%;
	/* transform: scale(0.5); */
}

.circularG{
	position:absolute;
	background-color: #FFF5C2;
	width:16px;
	height:16px;
	border-radius:10px;
		-o-border-radius:10px;
		-ms-border-radius:10px;
		-webkit-border-radius:10px;
		-moz-border-radius:10px;
	animation-name:bounce_circularG;
		-o-animation-name:bounce_circularG;
		-ms-animation-name:bounce_circularG;
		-webkit-animation-name:bounce_circularG;
		-moz-animation-name:bounce_circularG;
	animation-duration:1.1s;
		-o-animation-duration:1.1s;
		-ms-animation-duration:1.1s;
		-webkit-animation-duration:1.1s;
		-moz-animation-duration:1.1s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
}

#circularG_1{
	left:0;
	top:27px;
	animation-delay:0.41s;
		-o-animation-delay:0.41s;
		-ms-animation-delay:0.41s;
		-webkit-animation-delay:0.41s;
		-moz-animation-delay:0.41s;
}

#circularG_2{
	left:7px;
	top:7px;
	animation-delay:0.55s;
		-o-animation-delay:0.55s;
		-ms-animation-delay:0.55s;
		-webkit-animation-delay:0.55s;
		-moz-animation-delay:0.55s;
}

#circularG_3{
	top:0;
	left:27px;
	animation-delay:0.69s;
		-o-animation-delay:0.69s;
		-ms-animation-delay:0.69s;
		-webkit-animation-delay:0.69s;
		-moz-animation-delay:0.69s;
}

#circularG_4{
	right:7px;
	top:7px;
	animation-delay:0.83s;
		-o-animation-delay:0.83s;
		-ms-animation-delay:0.83s;
		-webkit-animation-delay:0.83s;
		-moz-animation-delay:0.83s;
}

#circularG_5{
	right:0;
	top:27px;
	animation-delay:0.97s;
		-o-animation-delay:0.97s;
		-ms-animation-delay:0.97s;
		-webkit-animation-delay:0.97s;
		-moz-animation-delay:0.97s;
}

#circularG_6{
	right:7px;
	bottom:7px;
	animation-delay:1.1s;
		-o-animation-delay:1.1s;
		-ms-animation-delay:1.1s;
		-webkit-animation-delay:1.1s;
		-moz-animation-delay:1.1s;
}

#circularG_7{
	left:27px;
	bottom:0;
	animation-delay:1.24s;
		-o-animation-delay:1.24s;
		-ms-animation-delay:1.24s;
		-webkit-animation-delay:1.24s;
		-moz-animation-delay:1.24s;
}

#circularG_8{
	left:7px;
	bottom:7px;
	animation-delay:1.38s;
		-o-animation-delay:1.38s;
		-ms-animation-delay:1.38s;
		-webkit-animation-delay:1.38s;
		-moz-animation-delay:1.38s;
}



@keyframes bounce_circularG{
	0%{
		transform:scale(1);
	}

	100%{
		transform:scale(.3);
	}
}

@-o-keyframes bounce_circularG{
	0%{
		-o-transform:scale(1);
	}

	100%{
		-o-transform:scale(.3);
	}
}

@-ms-keyframes bounce_circularG{
	0%{
		-ms-transform:scale(1);
	}

	100%{
		-ms-transform:scale(.3);
	}
}

@-webkit-keyframes bounce_circularG{
	0%{
		-webkit-transform:scale(1);
	}

	100%{
		-webkit-transform:scale(.3);
	}
}

@-moz-keyframes bounce_circularG{
	0%{
		-moz-transform:scale(1);
	}

	100%{
		-moz-transform:scale(.3);
	}
}



@keyframes f_fadeG{
	0%{
		background-color:rgb(0,0,0);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-o-keyframes f_fadeG{
	0%{
		background-color:rgb(0,0,0);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-ms-keyframes f_fadeG{
	0%{
		background-color:rgb(0,0,0);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-webkit-keyframes f_fadeG{
	0%{
		background-color:rgb(0,0,0);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-moz-keyframes f_fadeG{
	0%{
		background-color:rgb(0,0,0);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

.mode-of-payment {
  width: 200px;
  border-radius: 20px;
  font-size: 13px;
}

.mode-of-payment-dropdown__control {
  border-radius: 10px !important;
}

.bill-input {
  width: 175px !important;
  text-align: right;
}

.bill-field-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.createGeneralGrid {
  display: grid;
  grid-gap: 10px 30px;
  gap: 10px 30px;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(6, 4rem);
}

.salesBillContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: end; */
  gap: 10px;
}

.DetailsBox {
  box-shadow: 0 1px 5px 1px #d3d3d3;
  padding-bottom: 10px;
  margin-top: 40px;
}

.Tabs {
  display: flex;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.individualTab {
  border-color: #fff #fff transparent;
  background-color: #e4e4e4;
  cursor: pointer;
  flex: 1;
  border: 2px solid #fff;
  border-bottom: 2px solid transparent;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  line-height: 40px;
}

.individualTabDetails {
  /* display: grid;
  grid-gap: 10px 30px;
  gap: 10px 30px;
  grid-template-columns: repeat(16, 1fr); */
  padding: 20px;
  /* grid-template-rows: repeat(2, 4rem); */
}

.individualTab.active {
  color: #1f43e5;
  background-color: #fff;
  border: 2px solid;
  border-color: #fff #fff #1f43e5;
  font-weight: 500;
}

.formSubmitArea {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-top: 1px solid #f2e0f7;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.formSubmitInnerArea {
  width: 1184px;
  margin: 10px 0px;
  display: flex;
  justify-content: end;
  gap: 25px;
}

.formSubmitErrorTextArea {
  flex: 1 1;
  color: red;
  font-size: 20px;
  margin: auto;
  text-align: left;
}

.TopTabs {
  display: flex;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  font-size: 12px;
  margin: 0.5rem 1rem 0;
  min-height: 50px;
  min-width: 100%;
  height: auto;
  overflow-x: scroll;
}

.dashboardParams {
  display: flex;
  padding: 0.3rem;
  flex-direction: row;
  margin: 1px 0 0 1px;
  width: 195%;
  max-height: 20vh;
  grid-column: 1 / auto;
  grid-row: 1/1;
}
